import React from "react"
import { StyledContainer } from "../../styled/Container"
import { withMentorFeedback } from "./withMentorFeedback"
import { Title, Text } from "./MentorFeedback.styled"
import { Wizard } from "../Wizard/Wizard"
export const MentorFeedback = withMentorFeedback(
  ({ handleSubmit, mentor, handleOnSelect, selectedRating, loading }: Props): JSX.Element => {
  return (
    <>
      <StyledContainer topSpacing={`md`} width={`xl`}>
        <Title spacing>Leave feedback for {mentor?.title}</Title>
      </StyledContainer>
      <StyledContainer collapse={`<md`} width={`lg`}>
        <Wizard
          steps={[
            {
              id: "overall",
              title: `From 1-10 rate your experience with ${mentor?.title}`,
              rating: `Overall`,
            },
            {
              id: "feedback",
              title: `Want to tell us more? Leave details`,
              textInput: "0/200",
            },
          ]}
          backLink={"/account/reviews"}
          doneButton="Submit Review"
          selected={selectedRating}
          onSelect={handleOnSelect}
          onComplete={handleSubmit}
          loading={loading}
        />
      </StyledContainer>
    </>
  )
})

export interface Props {
  mentor?: any
  selectedRating?: number
  loading?: boolean
  handleSubmit?: () => void
  handleOnSelect?: (any) => void
}
